import {
  OrderGraph,
  OrderByStatus,
  OrderByFood,
  OrderTrackingResponse,
  SalesByAccount,
  SalesByFoodResponse,
  SalesPerMonth,
  SalesReport,
  InventoryReport,
  PayrollReport
} from 'src/app/feature/Report/models/Report';
import {
  GET_ORDERS_GRAPH,
  GET_ORDERS_BY_STATUS,
  GET_ORDERS_BY_FOOD,
  GET_ORDERS_TRACKING,
  GET_SALES_BY_ACCOUNT,
  GET_SALES_BY_FOOD,
  GET_SALES_PER_MONTH,
  RESET_REPORTS,
  GET_SALES_REPORT,
  GET_INVENTORY_REPORT,
  GET_PAYROLL_REPORT,
  TypesActionsReport
} from './ReportTypes';
import { ReportsDao } from 'src/app/core/api/reports/dao/reports.dao';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';

function getOrdersGraph(payload: OrderGraph[]): TypesActionsReport {
  return {
    type: GET_ORDERS_GRAPH,
    payload
  };
}

function getOrdersByStatus(payload: OrderByStatus[]): TypesActionsReport {
  return {
    type: GET_ORDERS_BY_STATUS,
    payload
  };
}

function getOrdersByFood(payload: OrderByFood[]): TypesActionsReport {
  return {
    type: GET_ORDERS_BY_FOOD,
    payload
  };
}

function getOrderTracking(payload: OrderTrackingResponse): TypesActionsReport {
  return {
    type: GET_ORDERS_TRACKING,
    payload
  };
}

function getSalesByAccount(payload: SalesByAccount[]): TypesActionsReport {
  return {
    type: GET_SALES_BY_ACCOUNT,
    payload
  };
}

function getSalesByFood(payload: SalesByFoodResponse): TypesActionsReport {
  return {
    type: GET_SALES_BY_FOOD,
    payload
  };
}

function getSalesPerMonth(payload: SalesPerMonth): TypesActionsReport {
  return {
    type: GET_SALES_PER_MONTH,
    payload
  };
}

export function resetReports(): TypesActionsReport {
  return {
    type: RESET_REPORTS
  };
}

function getSalesReport(payload: SalesReport): TypesActionsReport {
  return {
    type: GET_SALES_REPORT,
    payload
  };
}

function getInventoryReport(payload: InventoryReport): TypesActionsReport {
  return {
    type: GET_INVENTORY_REPORT,
    payload
  };
}

function getPayrollReport(payload: PayrollReport): TypesActionsReport {
  return {
    type: GET_PAYROLL_REPORT,
    payload
  };
}

export function getOrdersGraphAsync(
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispatch: Function) {
    const response = await ReportsDao.getOrdersGraph({ from, to, account });
    dispatch(getOrdersGraph(response.data));
  };
}

export function getOrdersByStatusAsync(
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispacth: Function) {
    const response = await ReportsDao.getOrdersByStatus({ from, to, account });
    dispacth(getOrdersByStatus(response.data));
  };
}

export function getOrdersByFoodAsync(
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispatch: Function) {
    const response = await ReportsDao.getOrdersByFood({ from, to, account });
    dispatch(getOrdersByFood(response.data));
  };
}

export function getOrdersTrackingAsync(
  page: number,
  limit: number,
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispacth: Function) {
    const response = await ReportsDao.getOrdersTracking({
      page,
      limit,
      from,
      to,
      account
    });
    dispacth(getOrderTracking(response.data));
  };
}

export async function getOrdersExcel(
  from: string,
  to: string,
  account: string[]
) {
  try {
    return await ReportsDao.getOrdersExcel({ from, to, account });
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}

export function getSalesByAccountAsync(
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispacth: Function) {
    const response = await ReportsDao.getSalesByAccount({ from, to, account });
    dispacth(getSalesByAccount(response.data));
  };
}

export function getSalesByFoodAsync(
  page: number,
  limit: number,
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispatch: Function) {
    const response = await ReportsDao.getSalesByFood({
      page,
      limit,
      from,
      to,
      account
    });
    dispatch(getSalesByFood(response.data));
  };
}

export function getSalesPerMonthAsync(
  from: string,
  to: string,
  account: string[]
) {
  return async function (dispatch: Function) {
    const response = await ReportsDao.getSalesPerMonth({ from, to, account });
    dispatch(getSalesPerMonth(response.data));
  };
}

export async function getSalesExcel(
  from: string,
  to: string,
  account: string[]
) {
  try {
    return await ReportsDao.getSalesExcel({ from, to, account });
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}

export function getSalesReportAsync(
  dateFrom: string,
  dateTo: string,
  accounts?: string[],
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispatch: Function) {
    try {
      let query = '';
      if (accounts && Array.isArray(accounts) && accounts.length > 0) {
        accounts.forEach((account) => {
          query += `&accountId=${account}`;
        });
      }
      const response = await ReportsDao.salesReport(dateFrom, dateTo, query);
      dispatch(getSalesReport(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      let message = 'Ha ocurrido un error';
      if (request && request.response) {
        message = `${JSON.parse(request.response).message}`;
      }
      Alert({
        title: 'Error',
        text: message,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function getInventoryReportAsync(
  dateFrom: string,
  dateTo: string,
  accounts?: string[],
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispatch: Function) {
    try {
      let query = '';
      if (accounts && Array.isArray(accounts) && accounts.length > 0) {
        accounts.forEach((account) => {
          query += `&accountId=${account}`;
        });
      }
      const response = await ReportsDao.inventoryReport(
        dateFrom,
        dateTo,
        query
      );
      dispatch(getInventoryReport(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function getPayrollReportAsync(
  dateFrom: string,
  dateTo: string,
  accounts?: string[],
  userId?: string,
  employees?: string[],
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispatch: Function) {
    try {
      let query = '';
      if (accounts && Array.isArray(accounts) && accounts.length > 0) {
        accounts.forEach((account) => {
          query += `&accountId=${account}`;
        });
      }
      if (employees && Array.isArray(employees) && employees.length > 0) {
        employees.forEach((employee) => {
          query += `&employeeId=${employee}`;
        });
      }
      const response = await ReportsDao.payrollReport(
        dateFrom,
        dateTo,
        userId,
        query
      );
      dispatch(getPayrollReport(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}
